//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache, Invalidate } from "../decorators/cache.decorator";

export class SettingsService {
    @Cache({ targetName: "SettingsService", immutable: true })
    public static getSettings() {
        return DataAccess.get("/public/settings/series25.json").then((resp) => {
            let ret: any = {};
            jSith.forEach(resp && resp.attrs && resp.attrs.attr, (key: any, value: any) => {
                ret[value.attr_name] = value.attr_value;
            });
            return ret;
        });
    }

    @Invalidate({ serviceName: "SettingsService", methodName: "getSettings" })
    public static setSetting(setting: string, value: any) {
        return DataAccess.put("/public/settings/series25.json?attr_name=" + setting + "&attribute_value=" + value);
    }
}
