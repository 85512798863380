import { PreferenceService } from "./preference.service";
import { CustomAttributeService } from "./custom.attribute.service";
import { EventService } from "./event.service";
import { SearchCriteriaService } from "./search/search-criteria/search.criteria.service";
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";

const SOC_CA_ID = -22;
export class StandoutClassroomService {
    /**
     * IF SOC preference is set and contains a URL, return it. Otherwise return empty string
     **/
    @Cache({ targetName: "StandoutClassroomService" })
    public static getUrl(checkPref?: boolean) {
        return PreferenceService.getPreferences(["standout_classroom"], "S").then((prefs) => {
            return prefs["standout_classroom"]?.value || "";
        });
    }

    /**
     * Gets the redirect URL for the standout classroom.
     *
     * @param {boolean} [checkPref] - When true, checks if the preference is set before returning the URL. We may treat SOC differently when pref is not explicitly enabled
     * @param {string} [path] - The path to redirect to deep link eg. /init/.
     * @returns {Promise<string>} - A promise that resolves to the redirect URL if available, otherwise an empty string.
     */
    public static async getRedirectUrl(checkPref?: boolean, path?: string) {
        if (checkPref) {
            let url = await StandoutClassroomService.getUrl();
            url = url?.endsWith("/") ? url.slice(0, -1) : url;
            path = url + path?.startsWith("/") ? path : `/${path}`;
        }
        return DataAccess.getUrl(`/redirect/app.json?app=soc${path ? `&redirect=${path}` : ""}`);
    }

    /*
    Has SOC been explicitly turned on for the instance
     */
    public static async instanceHasSOC() {
        const socValue = await PreferenceService.getPreferences(["standout_classroom"], "S");
        return !!socValue["standout_classroom"]?.value;
    }

    /*
    Checks whether an event is currently standout classroom configured
    no custom attr set means null - not yet set
     */
    public static eventHasSOC(eventId: number) {
        return Promise.resolve(true);
    }

    /*
    Sets changes the SOC state of an event to the current value by setting the required custom attribute
     */
    public static eventSetSOC(eventId: number, value: boolean = true) {
        return CustomAttributeService.putEventCustomAttribute([eventId], SOC_CA_ID, "B", value ? 1 : 0);
    }

    /*
    Checks the event type and returns if an event can have SOC configured
     */
    public static async canHaveSOCByEvent(eventId: number) {
        if (!(await StandoutClassroomService.instanceHasSOC())) return false;

        let eventTypeId = await EventService.getEventType(eventId);
        return StandoutClassroomService.canHaveSOCByEventType(eventTypeId);
    }

    /*
    Checks the event type and returns if an event can have SOC configured
    */
    public static async canHaveSOCByEventType(eventTypeId: number) {
        let items = await SearchCriteriaService.getCustomAttributeItems(1, true, eventTypeId);
        return S25Util.array.findByProp(items, "itemId", SOC_CA_ID) >= 0;
    }
}
